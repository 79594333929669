import axios from "axios";
import store from "@redux/store";
import { CLIENT_ID } from "utils/constants";
import { Toast } from "components";

function parseParams(params) {
  const keys = Object.keys(params);
  let options = "";

  keys.forEach((key) => {
    const isParamTypeObject = typeof params[key] === "object";
    const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

    if (!isParamTypeObject) {
      options += `${key}=${params[key]}&`;
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach((element) => {
        options += `${key}=${element}&`;
      });
    }
  });

  return options ? options.slice(0, -1) : options;
}

class HttpAdapterAsync {
  constructor(baseURL) {
    this.httpClient = axios.create({
      baseURL: baseURL,
    });
    this.setupInterceptors();
  }

  setupInterceptors() {
    this.httpClient.interceptors.request.use(this.authInterceptor.bind(this));
    this.httpClient.interceptors.response.use(
      this.successInterceptor.bind(this),
      this.errorInterceptor.bind(this),
    );
  }

  authInterceptor(config) {
    const token = this.getAuthToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Content-Type"] = "application/json";
      config.headers["Client"] = CLIENT_ID;
      config.headers["Realm"] = this.getTenant();
      config.headers["Accept-Language"] = this.getLanguage();
      config.headers["Time-Zone"] = this.getTimeZone();
    }

    return config;
  }

  successInterceptor(response) {
    return response;
  }

  errorInterceptor(error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 401:
          const { url } = error.config;
          if (url === "/auth/sign-in/keycloak") return;
          else {
            console.log("Su sesión ha terminado");
            setTimeout(() => {
              localStorage.clear();
              document.location.reload();
            }, 2000);
          }
      }
    }

    return Promise.reject(error);
  }

  getAuthToken() {
    const state = store.getState();
    const { accessToken } = state.authUser;
    return accessToken || null;
  }

  getLanguage() {
    const state = store.getState();
    const { language } = state?.ui?.preferences;
    return language?.abb || "es";
  }

  getTimeZone() {
    const state = store.getState();
    const { timeZone } = state?.ui;
    return timeZone || null;
  }

  getTenant() {
    const url = window.location.href;
    if (url.includes("localhost")) return process.env.REACT_APP_TENANT;
    const tenant = url.split(".")[0].split("//")[1];
    return tenant;
  }

  async request({ t, messageSuccess, messageError, ...request }) {
    messageSuccess = messageSuccess
      ? messageSuccess
      : t(`general:messageSuccessDefault`);
    messageError = messageError
      ? messageError
      : t(`general:messageErrorDefault`);

    try {
      let data = null;
      request["paramsSerializer"] = (params) => parseParams(params);
      const result = await this.httpClient.request(request);

      if (result.status === 202) {
        messageSuccess = result?.data?.message
          ? result?.data?.message
          : messageSuccess;
      } else {
        data = request.method === "DELETE" ? true : result.data;
      }

      if (request.method !== "GET") {
        Toast.fire({
          icon: "success",
          title: messageSuccess,
        });
      }

      return data;
    } catch (e) {
      console.log(e);

      messageError = e?.response?.data?.message
        ? e?.response?.data?.message
        : messageError;

      Toast.fire({
        icon: "error",
        title: messageError,
      });

      return false;
    }
  }
}

export default HttpAdapterAsync;
